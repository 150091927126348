import React from 'react';
import { Link } from 'gatsby';
import { siteMap } from '../../utils/sitemap';

export default function CTA({ cta }) {
  return (
    <div>
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block text-gray-200">{cta}</span>
          <span className="-mb-1 pb-1 block bg-gradient-to-r from-purple-tb-400 to-orange-tb-400 bg-clip-text text-transparent">
            Start now with 10 free verifications.
          </span>
        </h2>
        <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
          <Link
            to={siteMap.SIGN_UP.link}
            className="flex items-center justify-center bg-purple-tb-400 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-tb-400 hover:bg-purple-tb-300"
          >
            Start for free
          </Link>
        </div>
      </div>
    </div>
  );
}
