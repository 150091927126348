import React from 'react';
import MapImage from '../images/map.svg';
import ApiImage from '../images/api.svg';

export default function Example() {
  return (
    <div className="relative pt-8 pb-32 overflow-hidden">
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <MapImage
                className="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none overflow-visible"
                alt="Customer profile user interface"
              />
            </div>
          </div>
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-black">
                  Formal and official registry check
                </h2>
                <p className="mt-4 text-lg text-gray-700">
                  We validate VAT IDs both formally and against national
                  databases. You always get the best validation possible, ready
                  for any tax audit. We support all the EU countries, UK and
                  Norway (more will be added soon).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-black">
                  API powered
                </h2>
                <p className="mt-4 text-lg text-gray-700">
                  You can verify VAT IDs on demand with our API. Create
                  verifications and get the results in real-time or
                  asynchronously in case the national database is not available.
                  We take care of the technical complexity to give you the
                  result as soon as available.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <ApiImage
                className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none overflow-visible"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
