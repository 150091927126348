import React from 'react';

function Features({ title, description, features }) {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
      <h2 className="text-3xl font-extrabold text-white tracking-tight">
        {title}
      </h2>
      <p className="mt-4 max-w-3xl text-lg text-gray-600">{description}</p>
      <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
        {features.map((feature) => (
          <div key={feature.name}>
            <div>
              <span className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-100 bg-opacity-80">
                <feature.icon
                  className="h-6 w-6 text-black"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium text-black">{feature.name}</h3>
              <p className="mt-2 text-base text-gray-700">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
