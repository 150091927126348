import React from 'react';
import {
  DocumentDuplicateIcon,
  CurrencyEuroIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import Hero from '../components/hero';
import Feature from '../components/feature';
import Feature3 from '../components/feature3';
import CTA from '../components/cta';
import Layout from '../components/layout';
import Seo from '../components/seo';

const features = [
  {
    name: 'Bulk import & export',
    description:
      'You can check VAT IDs using the bulk CSV import or by using our API. Verified VAT IDs can also be exported to CSV.',
    icon: DocumentDuplicateIcon,
  },
  {
    name: 'Reliable data sources',
    description:
      'We validate VAT IDs using VIES and national databases, so you can always trust the result of the validation.',
    icon: CurrencyEuroIcon,
  },
  {
    name: 'Privacy and security first',
    description:
      'Our platform is hosted in the European Union and we comply with all the privacy and security regulations. ',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Storing verifications',
    description:
      'We store your verifications for you, so they are always available and you can export the data for audit or tax control reasons.',
    icon: DocumentTextIcon,
  },
];

function IndexPage({ location }) {
  return (
    <Layout>
      <Seo title="Easy verification for VAT IDs" path={location.pathname} />
      <Hero
        title={
          <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
            <span className="block">Easy and audit-proof </span>
            <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-purple-tb-400 bg-purple-tb-400 via-orange-tb-400 sm:pb-5">
              check of VAT IDs
            </span>
          </h1>
        }
        description="Be audit-proof with single or batch VAT IDs verification in real-time, with API or CSV."
        cta
      />
      <Feature />
      <Feature3
        // title="Pay and getting paid: Done."
        // description="We make pay your suppliers and getting paid from your customers as easy as possible. You can track your suppliers' invoices, receive reminders and be always on top of collecting your invoices from your customers right on time."
        features={features}
      />
      <CTA />
    </Layout>
  );
}

export default IndexPage;
